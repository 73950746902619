var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",staticClass:"role-permissions-form",attrs:{"lazy-validation":"","aria-busy":_vm.loading,"disabled":_vm.loading,"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"form-col",attrs:{"cols":"4"}},[_c('v-select',{attrs:{"item-text":"name","items":_vm.roles,"label":"Role","required":""},on:{"input":function (e) { return (_vm.permissionSettings.role = _vm.roles.find(function (r) { return r.name === e; })); }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("role")(item.name))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("role")(item.name))+" ")]}}]),model:{value:(_vm.permissionSettings.role),callback:function ($$v) {_vm.$set(_vm.permissionSettings, "role", $$v)},expression:"permissionSettings.role"}}),_c('alert',{attrs:{"error":{
          message:
            _vm.permissionSettings.permissions && _vm.permissionSettings.permissions.length === 0
              ? 'Permissions are required'
              : '',
        }}}),_c('div',{staticClass:"mt-1"},[_c('permissions',{attrs:{"disabled":!!_vm.permissionSettings.role},model:{value:(_vm.permissionSettings.permissions),callback:function ($$v) {_vm.$set(_vm.permissionSettings, "permissions", $$v)},expression:"permissionSettings.permissions"}})],1)],1)],1),_c('v-row',{staticClass:"mt-8",attrs:{"no-gutters":"","justify":"end"}},[_c('btn',{attrs:{"type":"submit","width":"120","disabled":_vm.loading || _vm.disableBtn}},[_vm._v("Save")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }